@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-white: #fff;
    --color-cream-white: #f3f5ff;
    --color-dark: #222;
    --color-gray: #747474;
    --color-accent: #4659fe;
    --color-gray-blue: #a4b1e7;
    --color-dark-blue: #123057;

    .spinner-circle {
      stroke-linecap: round;
      transform-origin: center;
      animation:
        spinner-dash 1.5s ease-in-out infinite,
        rotate 2s linear infinite;
    }

    @keyframes spinner-dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }

      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }

    @keyframes rotate {
      100% {
        transform: rotate(360deg);
      }
    }
  }

  html {
    @apply font-body;

    background-color: var(--color-cream-white);
  }
}
