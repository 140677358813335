// Montserrat
@font-face {
  font-family: Montserrat;
  font-weight: 100;
  src: url('~assets/fonts/Montserrat/Montserrat-Thin.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 200;
  src: url('~assets/fonts/Montserrat/Montserrat-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 300;
  src: url('~assets/fonts/Montserrat/Montserrat-Light.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 400;
  src: url('~assets/fonts/Montserrat/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 500;
  src: url('~assets/fonts/Montserrat/Montserrat-Medium.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 600;
  src: url('~assets/fonts/Montserrat/Montserrat-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 700;
  src: url('~assets/fonts/Montserrat/Montserrat-Bold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 800;
  src: url('~assets/fonts/Montserrat/Montserrat-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: Montserrat;
  font-weight: 900;
  src: url('~assets/fonts/Montserrat/Montserrat-Black.woff2') format('woff2');
}

// SpaceMono
@font-face {
  font-family: SpaceMono;
  font-weight: 400;
  src: url('~assets/fonts/SpaceMono/SpaceMono-Regular.woff2') format('woff2');
}

@font-face {
  font-family: SpaceMono;
  font-weight: 700;
  src: url('~assets/fonts/SpaceMono/SpaceMono-Bold.woff2') format('woff2');
}
