html,
body,
#root {
  display: flex;
  flex-direction: column;
  flex: 1;
}

html,
body {
  height: 100%;
}
